import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pass-container" }
const _hoisted_2 = { class: "pass-wrap remind-pass-wrap" }
const _hoisted_3 = { class: "new-pass-inputs mb-16px" }
const _hoisted_4 = ["value", "onInput"]
const _hoisted_5 = {
  key: 1,
  class: "password-error"
}

import { toRefs, ref, computed } from "vue";
import { isNumberStrict } from "@/utilities/number";
import Modal from "@/components/elements/Modal.vue";
import PasswordInput from "@/components/elements/inputs/PasswordInput.vue";
import AuthBtn from "@/components/elements/buttons/AuthBtn.vue";
import ErrorMsg from "@/components/elements/form/ErrorMsg.vue";
import { useInput } from "@/composables/useInput";


export default /*@__PURE__*/_defineComponent({
  __name: 'RecoveryPasswordModal',
  props: {
    open: { type: Boolean },
    data: {}
  },
  emits: ["close", "showLogin", "sendRecoveryPassword"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { open } = toRefs(props);
const pinCodes = ref(Array(6).fill(""));
const clearPincodes = () => {
  pinCodes.value = Array(6).fill("");
};

const {
  isValidPassword,
  isValidRepeatPassword,
  password,
  repeatPassword,
  clearInputs,
  clearErrorMsg,
  errorMessage,
} = useInput("code");

const $emit = __emit;

const onLogin = () => {
  $emit("showLogin");
  clearInputs();
};

const onHandleClose = () => {
  clearInputs();
  clearPincodes();
  $emit("close");
};

const checkPinCode = (index: number, event: Event) => {
  const target = event.target as HTMLInputElement;
  const value = target.value.trim().replace(/[\D+]/g, ""); // Get the value of the input, remove non-digit characters
  if (value.length > 1) {
    target.value = value[0]; // Ensure only one character is in the input
  }
  pinCodes.value[index] = target.value; // Update the corresponding value in the array

  const prevEl = target.previousElementSibling as HTMLInputElement; // Previous sibling element
  const nextEl = target.nextElementSibling as HTMLInputElement; // Next sibling element

  if (value.length < 1 && prevEl) {
    prevEl.focus(); // Focus previous input if current input is empty
  } else if (value.length === 1 && nextEl) {
    nextEl.focus(); // Focus next input if current input has one character
  }
};

const arePinCodesFilled = computed(() => {
  return pinCodes.value.every((pin) => pin !== "");
});

const disabledRecovery = computed(() => {
  return (
    !arePinCodesFilled.value ||
    !password.value ||
    !repeatPassword.value ||
    !isValidPassword.value ||
    !isValidRepeatPassword.value ||
    !isSamePassword.value
  );
});

const isSamePassword = computed(() => {
  if (!isValidPassword.value || !isValidRepeatPassword.value) {
    return true;
  }
  return password.value === repeatPassword.value;
});

const onHandleSubmit = () => {
  $emit(
    "sendRecoveryPassword",
    {
      phone: props.data?.value,
      code: pinCodes.value.join(""),
      newPassword: password.value,
      newPasswordConfirm: repeatPassword.value,
    },
    onHandleClose
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    open: _unref(open),
    onClose: onHandleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "mb-8px" }, "Введите 6-значный код и новый пароль", -1)),
          _cache[6] || (_cache[6] = _createElementVNode("div", null, [
            _createElementVNode("span", { class: "pass-input-label new-pass-input-label" }, " Одноразовый код отправлен Вам на указанный номер телефона. ")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pinCodes.value, (pin, index) => {
              return (_openBlock(), _createElementBlock("input", {
                key: index,
                class: "new-pass-input",
                autocomplete: "off",
                value: pin,
                onKeypress: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(isNumberStrict) && _unref(isNumberStrict)(...args))),
                onInput: _withModifiers((e) => checkPinCode(index, e), ["stop"]),
                inputmode: "numeric",
                pattern: "[0-9]*",
                maxlength: "1"
              }, null, 40, _hoisted_4))
            }), 128))
          ]),
          _createElementVNode("form", {
            class: "w-full",
            onSubmit: _withModifiers(onHandleSubmit, ["prevent"])
          }, [
            (_unref(errorMessage))
              ? (_openBlock(), _createBlock(ErrorMsg, {
                  key: 0,
                  errorMsg: _unref(errorMessage),
                  onClear: _unref(clearErrorMsg)
                }, null, 8, ["errorMsg", "onClear"]))
              : _createCommentVNode("", true),
            _createVNode(PasswordInput, {
              hint: "Придумайте пароль (Не менее 7 символов, цифр — не менее 1, строчных латинских букв — не менее\n      1, прописных латинских букв — не менее 1)",
              placeholder: "Пароль",
              class: "mb-16px",
              isValidPassword: _unref(isValidPassword),
              "modal-value": _unref(password),
              "onUpdate:modalValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(password) ? (password).value = $event : null))
            }, null, 8, ["isValidPassword", "modal-value"]),
            _createVNode(PasswordInput, {
              hint: "Повторите пароль",
              placeholder: "Пароль",
              isValidPassword: _unref(isValidRepeatPassword),
              "enabled-validation": false,
              "modal-value": _unref(repeatPassword),
              "onUpdate:modalValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(repeatPassword) ? (repeatPassword).value = $event : null))
            }, null, 8, ["isValidPassword", "modal-value"]),
            (!isSamePassword.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Пароли должны совпадать. "))
              : _createCommentVNode("", true),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mb-32px" }, null, -1)),
            _createVNode(AuthBtn, {
              title: "Готово",
              disabled: disabledRecovery.value,
              type: "submit"
            }, null, 8, ["disabled"]),
            _createElementVNode("div", { class: "link-sign-in" }, [
              _cache[3] || (_cache[3] = _createTextVNode(" Есть аккаунт? ")),
              _createElementVNode("span", { onClick: onLogin }, "Вход")
            ])
          ], 32)
        ])
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}
}

})