export function addDays(daysToAdd: number): string {
  const date: Date = new Date();

  date.setDate(date.getDate() + daysToAdd);

  const resultDay: string = String(date.getDate()).padStart(2, "0");
  const resultMonth: string = String(date.getMonth() + 1).padStart(2, "0");
  const resultYear: number = date.getFullYear();

  return `${resultDay}.${resultMonth}.${resultYear}`;
}

export const formatDate = (date: Date | string) => {
  const dateCurrent =
    typeof date === "string"
      ? new Date(
          (date as any).split(".")[2],
          (date as any).split(".")[1] - 1,
          (date as any).split(".")[0]
        )
      : date;
  const day = String(dateCurrent.getDate()).padStart(2, "0");
  const month = String(dateCurrent.getMonth() + 1).padStart(2, "0");
  const year = dateCurrent.getFullYear();

  return `${day}.${month}.${year}`;
};

export const formatDateLatin = (date: Date | string) => {
  const dateCurrent = typeof date === "string" ? new Date(date) : date;
  const day = String(dateCurrent.getDate()).padStart(2, "0");
  const month = String(dateCurrent.getMonth() + 1).padStart(2, "0");
  const year = dateCurrent.getFullYear();

  return `${year}-${month}-${day}`;
};

export const formatDateLatinForRequest = (date: Date | string) => {
  const dateCurrent =
    typeof date === "string"
      ? new Date(
          (date as any).split(".")[2],
          (date as any).split(".")[1] - 1,
          (date as any).split(".")[0]
        )
      : date;
  const day = String(dateCurrent.getDate()).padStart(2, "0");
  const month = String(dateCurrent.getMonth() + 1).padStart(2, "0");
  const year = dateCurrent.getFullYear();

  return `${year}-${month}-${day}`;
};

export function parseDate(dateStr: string) {
  const parts = dateStr.split(".");
  if (parts.length === 3) {
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }
  return null;
}
