import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "bg-light pt-5 pb-5 mb-2",
  style: {"padding-bottom":"66px !important"}
}
const _hoisted_2 = { class: "container trending-products" }
const _hoisted_3 = { class: "heading heading-flex mb-3" }
const _hoisted_4 = { class: "heading-right" }
const _hoisted_5 = { class: "tab-content-goods tab-content-carousel just-action-icons-sm h-100" }
const _hoisted_6 = {
  class: "tab-pane p-0 fade show active h-100",
  id: "trendy-top-rated"
}
const _hoisted_7 = { class: "swiper-carousel carousel-with-shadow swiper-1 h-100" }

import { ref, toRef, computed } from "vue";
import Tabs from "../../elements/Tabs.vue";
import GoodItemSlider from "@/components/elements/goods/GoodItemSlider.vue";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { GoodProduct } from "@/api/models/GoodProduct";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Certificate } from "@/api/models/Certificate";
import { CommonCategory } from "@/api/models/CommonCategory";


export default /*@__PURE__*/_defineComponent({
  __name: 'TrendyCollection',
  props: {
    products: {},
    certificate: {}
  },
  setup(__props: any) {

const props = __props;
const products = toRef(props, "products");
const certificate = toRef(props, "certificate");
const allProducts = computed(() => {
  return [
    ...products.value
      .slice(0, 4)
      .map((product) => ({ ...product, type: "Good" })),
    { ...certificate.value, type: "Cert" },
  ].sort(() => 0.5 - Math.random());
});

const tabsData = ref([
  {
    id: "top-rated",
    title: "Все",
    url: "/shop/catalogue/goods?specialFilter=Popular",
    active: true,
  },
]);

const modules = [Navigation, Pagination, Scrollbar];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "heading-left" }, [
          _createElementVNode("h2", { class: "title" }, "Популярные товары")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(Tabs, {
            class: "nav nav-pills nav-border-anim justify-content-center",
            data: tabsData.value,
            id: "trendy"
          }, null, 8, ["data"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(Swiper), {
              breakpoints: {
                '320': {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                '576': {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },

                '768': {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                '1200': {
                  slidesPerView: 5,
                },
                '1201': {
                  slidesPerView: 5,
                  spaceBetween: 35,
                },
              },
              modules: modules,
              navigation: false,
              slidesPerView: 1,
              spaceBetween: 10,
              class: "row h-100"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allProducts.value, (product, index) => {
                  return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                    class: "good-card",
                    key: index
                  }, {
                    default: _withCtx(() => [
                      _createVNode(GoodItemSlider, {
                        currentIndex: index,
                        product: product,
                        id: "category_carousel"
                      }, null, 8, ["currentIndex", "product"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}
}

})