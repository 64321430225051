import api from "@/repositories/repository";

export const subscribe = async (email: string) => {
  return await api.post(
    "/api/subscribe",
    {},
    {
      params: {
        email,
      },
    }
  );
};
