import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  toRefs,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import ExitIcon from "@/assets/images/icons/iconsComp/ExitIcon.vue";
import FavoriteEmptyIcon from "@/assets/images/icons/iconsComp/FavoriteEmptyIcon.vue";
import ListIcon from "@/assets/images/icons/iconsComp/ListIcon.vue";
import UserBlackIcon from "@/assets/images/icons/iconsComp/UserBlackIcon.vue";
import OperationsIcon from "@/assets/images/icons/iconsComp/OperationsIcon.vue";
import { useAuth } from "@/composables/useAuth";

// const props = defineProps<{}>();

export default /*@__PURE__*/_defineComponent({
  __name: 'PersonalMenu',
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const { onLogout } = useAuth();
const $emit = __emit;
const menuContainer = ref<HTMLElement | null>(null);

// const {} = toRefs(props);

const handleClickOutside = (event: Event) => {
  const target = event.target as HTMLElement;
  if (
    menuContainer.value &&
    !menuContainer.value.contains(target) &&
    !target.classList.contains("text-truncate") &&
    !target.parentElement?.parentElement?.classList.contains("text-truncate")
  ) {
    $emit("close");
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "menuContainer",
    ref: menuContainer,
    class: "container-truncate h-100"
  }, [
    _createElementVNode("div", {
      class: "list-truncate",
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($emit('close')))
    }, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { class: "link-truncate" }, {
            default: _withCtx(() => [
              _createVNode(UserBlackIcon),
              _cache[2] || (_cache[2] = _createElementVNode("span", null, "Профиль", -1))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { class: "link-truncate" }, {
            default: _withCtx(() => [
              _createVNode(FavoriteEmptyIcon),
              _cache[3] || (_cache[3] = _createElementVNode("span", null, "Избранное", -1))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            class: "link-truncate",
            to: {
              name: 'AccountOrders',
            }
          }, {
            default: _withCtx(() => [
              _createVNode(ListIcon),
              _cache[4] || (_cache[4] = _createElementVNode("span", null, "Заказы", -1))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            class: "link-truncate",
            to: {
              name: 'Operations',
            }
          }, {
            default: _withCtx(() => [
              _createVNode(OperationsIcon),
              _cache[5] || (_cache[5] = _createElementVNode("span", null, "Операции", -1))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("div", {
            class: "link-truncate cursor-pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onLogout) && _unref(onLogout)(...args)))
          }, [
            _createVNode(ExitIcon),
            _cache[6] || (_cache[6] = _createElementVNode("span", null, "Выход", -1))
          ])
        ])
      ])
    ])
  ], 512))
}
}

})