import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/newImg/icons/bi_arrow-right.svg'
import _imports_1 from '../../../assets/newImg/icons/bl_arrow-right.svg'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-6 deal-col" }
const _hoisted_4 = { class: "deal deal1" }
const _hoisted_5 = { class: "deal-content" }
const _hoisted_6 = { class: "product-title" }
const _hoisted_7 = { class: "product-price" }
const _hoisted_8 = { class: "col-lg-6 deal-col" }
const _hoisted_9 = { class: "deal deal2" }
const _hoisted_10 = { class: "deal-content" }
const _hoisted_11 = { class: "product-title" }
const _hoisted_12 = { class: "product-price" }
const _hoisted_13 = { class: "more-container text-center mt-1 mb-5" }

import { toRefs } from "vue";
import PriceFormatted from "@/components/elements/typography/PriceFormatted.vue";
import { formatDate } from "@/utilities/date";


export default /*@__PURE__*/_defineComponent({
  __name: 'DealCollection',
  props: {
    minPriceSaintPetersburg: {},
    minPriceKaliningrad: {}
  },
  setup(__props: any) {

const props = __props;

const { minPriceSaintPetersburg, minPriceKaliningrad } = toRefs(props);
const dateFrom = formatDate(
  new Date(new Date().setDate(new Date().getDate() + 7))
);
const dateTo = formatDate(
  new Date(new Date().setDate(new Date().getDate() + 10))
);
const queriesSaintPetersburg = {
  departure: JSON.stringify({
    id: "Москва-MOW",
    label: "Москва, любой аэропорт",
    value: "MOW",
    format: "RU",
  }),
  arrival: JSON.stringify({
    id: "Пулково-LED",
    label: "Санкт-Петербург, Пулково",
    value: "LED",
    format: "Россия",
  }),
  sort: "price",
  sortDirection: "Asc",
  dateFrom,
  dateTo,
  adt: 1,
  currentTab: "avias",
};
const queriesKaliningrad = {
  departure: JSON.stringify({
    id: "Москва-MOW",
    label: "Москва, любой аэропорт",
    value: "MOW",
    format: "RU",
  }),
  arrival: JSON.stringify({
    id: "Калининград-KGD",
    label: "Калининград, любой аэропорт",
    value: "KGD",
    format: "Россия",
  }),
  sort: "price",
  sortDirection: "Asc",
  dateFrom,
  currentTab: "avias",
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "heading text-center mb-3" }, [
      _createElementVNode("h2", { class: "title" }, "Популярные направления из Москвы"),
      _createElementVNode("p", { class: "title-desc" }, "Предложения дня")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "deal-top" }, [
            _createElementVNode("h2", { style: {"font-family":"Gilroy-Medium !important"} }, " Санкт-Петербург "),
            _createElementVNode("h4", null, "Авиабилеты")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h3", _hoisted_6, [
              _createVNode(_component_router_link, {
                to: {
                  name: 'FlightSearch',
                  query: queriesSaintPetersburg,
                },
                style: {"font-family":"Gilroy-Medium !important"}
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Туда - обратно")
                ])),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(PriceFormatted, {
                class: "new-price",
                style: {"font-family":"Gilroy-Medium !important"},
                price: _unref(minPriceSaintPetersburg),
                priceName: "бонусов"
              }, null, 8, ["price"])
            ]),
            _createVNode(_component_router_link, {
              to: {
                name: 'FlightSearch',
                query: queriesSaintPetersburg,
              },
              class: "btn btn-link",
              style: {"gap":"5px","margin-top":"40px"}
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("span", { style: {"font-family":"Gilroy-Medium !important"} }, "Бронировать", -1),
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1)
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "deal-bottom" }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "deal-top" }, [
            _createElementVNode("h2", { style: {"font-family":"Gilroy-Medium !important"} }, "Калининград"),
            _createElementVNode("h4", null, "Авиабилеты")
          ], -1)),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("h3", _hoisted_11, [
              _createVNode(_component_router_link, {
                to: {
                  name: 'FlightSearch',
                  query: queriesKaliningrad,
                }
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("В одну сторону")
                ])),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(PriceFormatted, {
                price: _unref(minPriceKaliningrad),
                "price-name": "бонусов",
                class: "new-price"
              }, null, 8, ["price"])
            ]),
            _createVNode(_component_router_link, {
              to: {
                name: 'FlightSearch',
                query: queriesKaliningrad,
              },
              class: "btn btn-link",
              style: {"gap":"5px","margin-top":"40px"}
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("span", { style: {"font-family":"Gilroy-Medium !important"} }, "Бронировать", -1),
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1)
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "deal-bottom" }, null, -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_router_link, {
        to: {
          name: 'Travels',
        },
        class: "btn btn-outline-dark-2 btn-round btn-more",
        style: {"gap":"5px","font-family":"Gilroy-Medium !important","font-weight":"600"}
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createElementVNode("span", { style: {"text-transform":"none !important","height":"16px"} }, "Смотреть все направления", -1),
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1)
        ])),
        _: 1
      })
    ])
  ]))
}
}

})