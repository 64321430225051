<template>
  <div class="cta-heading text-center">
    <h3 class="cta-title text-white">Получайте специальные предложения</h3>
    <p class="cta-desc text-white">подпишитесь на нашу рассылку</p>
  </div>
  <div>
    <div class="input-group input-group-round">
      <input
        ref="emailTooltip"
        name="email"
        type="email"
        placeholder="Введите эл. почту"
        class="form-control form-control-white"
        :class="{ 'input--invalid': !isValidEmail }"
        aria-label="Email Adress"
        v-tooltip.bottom="{
          value: 'Пожалуйста, заполните это поле',
          class: 'custom-tooltip',
          disabled: !showRequiredTooltip,
          autoHide: false,
        }"
        @keypress="isEmail"
        v-model="email"
      />
      <div class="input-group-append">
        <button
          class="btn btn-primary"
          type="submit"
          style="display: flex; gap: 5px"
          @click="onClickSubscribe"
        >
          <span style="display: flex; gap: 5px; color: #fff">
            <span style="height: 16px">Подписаться</span>
            <img src="../assets/newImg/icons/w_arrow-right.svg" alt="w_arrow" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useInput } from "@/composables/useInput";
import { subscribe } from "@/repositories/subscription";
import { isEmail } from "@/utilities/isEmail";
import { useApplicationStore } from "@/store/application";
import { useAuth } from "@/composables/useAuth";

const { email, isValidEmail } = useInput();
const { toggleToast, checkAuth } = useAuth();

const showRequiredTooltip = ref(false);
const applicationStore = useApplicationStore();

const emailTooltip = ref<HTMLElement | null>(null);

const onClickSubscribe = checkAuth(async () => {
  try {
    if (!email.value || !isValidEmail.value) {
      showRequiredTooltip.value = true;
      emailTooltip.value?.dispatchEvent(new Event("mouseenter"));
      return;
    }
    applicationStore.showLoader();
    await subscribe(email.value);
    toggleToast({
      type: "success",
      message: "Вы успешно подписались на рассылку",
    });
    email.value = "";
  } catch (error) {
    toggleToast({
      type: "error",
      message: "Произошла ошибка при подписке на рассылку",
    });
  } finally {
    applicationStore.hideLoader();
  }
});
</script>

<style scoped></style>
