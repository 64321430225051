import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

import { toRefs } from "vue";
import ClearBtn from "@/components/elements/buttons/ClearBtn.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
    open: { type: Boolean },
    customClass: {},
    maxWidth: { default: "573px" }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const { open, maxWidth } = toRefs(props);

const $emit = __emit;

const onHandleClose = (event: MouseEvent) => {
  if (event.target === event.currentTarget) {
    $emit("close");
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_unref(open))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["modal-container", _ctx.customClass]),
          onClick: onHandleClose
        }, [
          _createElementVNode("div", {
            class: "modal",
            style: _normalizeStyle({
          maxWidth: _unref(maxWidth),
        })
          }, [
            _renderSlot(_ctx.$slots, "default"),
            _createVNode(ClearBtn, {
              onClear: _cache[0] || (_cache[0] = ($event: any) => ($emit('close'))),
              class: "modal-close"
            })
          ], 4)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})