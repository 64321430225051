import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "carousel" }
const _hoisted_2 = { class: "container items-center" }
const _hoisted_3 = { class: "container swiper-item swiper_custom_item items-center" }
const _hoisted_4 = { class: "left-swiper-item" }
const _hoisted_5 = {
  key: 0,
  class: "right-swiper-item-img d-flex justify-content-center align-center"
}
const _hoisted_6 = ["alt", "src"]
const _hoisted_7 = { class: "right-swiper-item d-flex flex-column justify-content-center" }
const _hoisted_8 = { class: "swiper-item-desc" }
const _hoisted_9 = { class: "swiper-item-content" }
const _hoisted_10 = { class: "w-100 d-flex justify-content-start" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { style: {"height":"16px"} }

import { ref, onMounted, computed } from "vue";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useAuth } from "@/composables/useAuth";
import { useApplicationStore } from "@/store/application";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useRouter } from "vue-router";

interface Slide {
  image: string;
  name: string;
  description: string;
  is_card: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Carousel',
  setup(__props) {

const { isAuthUser, showLogin } = useAuth();
const showNavigation = ref(true);
const showPagination = ref(false);
const router = useRouter();

const applicationStore = useApplicationStore();

const statuses = computed(() => applicationStore.statuses);

const data = ref<Slide[]>([
  {
    image: "/assets/images/iphone.svg",
    name: "Товары",
    description: "За бонусы",
    is_card: false,
  },
  {
    image: "/assets/images/card.svg",
    name: "Подарки",
    description: "За бонусы",
    is_card: true,
  },
  {
    image: "/assets/images/plane.svg",
    name: "Отпуск",
    description: "За бонусы",
    is_card: false,
  },
  {
    image: "/assets/images/charity.svg",
    name: "Благотворительность",
    description: "Бонусы на",
    is_card: false,
  },
  {
    image: "/assets/images/apps.png",
    name: "Спецпредложения",
    description: "Для Вас",
    is_card: false,
  },
  {
    image: "/assets/images/action.png",
    name: "Акции",
    description: "Интересные",
    is_card: false,
  },
]);

const slides = computed(() => {
  return data.value.filter(
    (slide) =>
      (statuses.value?.goods && slide.name === "Товары") ||
      (statuses.value?.certificates && slide.name === "Подарки") ||
      ((statuses.value?.avia ||
        statuses.value?.railway ||
        statuses.value?.hotel) &&
        slide.name === "Отпуск") ||
      (statuses.value?.charity && slide.name === "Благотворительность") ||
      (statuses.value?.offers && slide.name === "Спецпредложения") ||
      slide.name === "Акции"
  );
});

const modules = [Navigation, Scrollbar, Pagination];

const shoWindow = () => {
  if (window.innerWidth <= 468) {
    showPagination.value = true;
    showNavigation.value = false;
  } else {
    showPagination.value = false;
    showNavigation.value = true;
  }
};

const onSwiper = (swiper: any) => {
  return swiper;
};

const onSlideChange = () => {
  console.log("slider change");
};

const redirectPage = (slide: {
  name: string;
  description: string;
  image: string;
  is_card: boolean;
}) => {
  debugger;
  if (slide.name === "Акции") {
    window.open("http://vamprivet.ru");
    return;
  }
  const pages = {
    Товары: {
      name: "GoodsStatic",
      query: {
        specialFilter: "Popular",
      },
    },
    Подарки: {
      name: "CertificatesStatic",
      query: {
        specialFilter: "Popular",
      },
    },
    Отпуск: {
      name: "Travels",
    },
    Благотворительность: {
      name: "Charities",
    },
    Спецпредложения: {
      name: "AdmitadsStatic",
    },
  };
  router.push(pages[slide.name as keyof typeof pages]);
};

onMounted(() => {
  shoWindow();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Swiper), {
        loop: true,
        modules: modules,
        navigation: showNavigation.value,
        "slides-per-view": 1,
        "space-between": 150,
        onSlideChange: onSlideChange,
        onSwiper: onSwiper,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        draggable: true,
        pagination: showPagination.value
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slides.value, (slide, index) => {
            return (_openBlock(), _createBlock(_unref(SwiperSlide), {
              style: {"min-height":"400px"},
              class: "slide_item",
              key: index
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (slide.image)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createElementVNode("img", {
                            format: "webp",
                            class: _normalizeClass(["main-img w-auto hero_img", slide?.is_card ? 'center_image' : null]),
                            loading: "lazy",
                            alt: slide.name,
                            src: slide.image
                          }, null, 10, _hoisted_6)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(slide.description), 1),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(slide.name), 1),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("a", {
                        class: "auth-btn text-white mb-2 cursor-pointer d-flex",
                        style: {"white-space":"nowrap"},
                        onClick: ($event: any) => (_unref(isAuthUser) ? redirectPage(slide) : _unref(showLogin)())
                      }, [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(isAuthUser) ? "Смотреть" : "Вход"), 1),
                        _cache[0] || (_cache[0] = _createElementVNode("svg", {
                          width: "16",
                          height: "16",
                          viewBox: "0 0 16 16",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }, [
                          _createElementVNode("path", {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M1 7.99999C1 7.86738 1.05268 7.74021 1.14645 7.64644C1.24021 7.55267 1.36739 7.49999 1.5 7.49999H13.293L10.146 4.35399C10.0521 4.2601 9.99937 4.13277 9.99937 3.99999C9.99937 3.86721 10.0521 3.73988 10.146 3.64599C10.2399 3.5521 10.3672 3.49936 10.5 3.49936C10.6328 3.49936 10.7601 3.5521 10.854 3.64599L14.854 7.64599C14.9006 7.69244 14.9375 7.74761 14.9627 7.80836C14.9879 7.8691 15.0009 7.93422 15.0009 7.99999C15.0009 8.06576 14.9879 8.13088 14.9627 8.19162C14.9375 8.25237 14.9006 8.30754 14.854 8.35399L10.854 12.354C10.7601 12.4479 10.6328 12.5006 10.5 12.5006C10.3672 12.5006 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.49999H1.5C1.36739 8.49999 1.24021 8.44731 1.14645 8.35354C1.05268 8.25978 1 8.1326 1 7.99999Z",
                            fill: "white"
                          })
                        ], -1))
                      ], 8, _hoisted_11)
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["navigation", "pagination"])
    ])
  ]))
}
}

})