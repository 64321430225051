import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "phone-input-wrap" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  class: "pass-input-label remind-pass-input-label text-left"
}

import { toRefs, ref, onMounted } from "vue";
import ClearBtn from "@/components/elements/buttons/ClearBtn.vue";
import { isNumber } from "@/utilities/number";
import IMask from "imask";


export default /*@__PURE__*/_defineComponent({
  __name: 'PhoneInput',
  props: {
    modelValue: {},
    isValidPhone: { type: Boolean },
    title: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const phoneInput = ref<HTMLInputElement | null>(null);

const $emit = __emit;

const { title, modelValue } = toRefs(props);

const updateValue = (event: Event) => {
  const input = event.target as HTMLInputElement;
  if (!input.value.startsWith("+7")) {
    const isEmpty = input.value === "" || input.value === "+";
    if (isEmpty) {
      input.value = "+7";
    } else {
      input.value = "+7" + input.value.replace(/^\+7/, "");
    }
  }
  input.value = "+7" + input.value.replace(/[^\d]/g, "").replace(/^7/, "");
  $emit("update:modelValue", input.value);
};

const clearInput = () => {
  $emit("update:modelValue", "+7");
};

onMounted(() => {
  if (phoneInput.value) {
    IMask(phoneInput.value, {
      mask: "+{7}(000)000-00-00",
    });
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        ref_key: "phoneInput",
        ref: phoneInput,
        name: "phone",
        type: "tel",
        placeholder: "+7(___)___-__-__",
        class: _normalizeClass(["iphone-only", { 'input--invalid': !_ctx.isValidPhone }]),
        value: _unref(modelValue),
        onInput: updateValue,
        onKeypress: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(isNumber) && _unref(isNumber)(...args))),
        maxlength: "16"
      }, null, 42, _hoisted_2),
      _createVNode(ClearBtn, {
        onClear: clearInput,
        class: "phone-input-clear"
      })
    ]),
    (_unref(title))
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(title)), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})