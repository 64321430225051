import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/newImg/icons/arrow_down.svg'


const _hoisted_1 = { class: "main-nav" }
const _hoisted_2 = { class: "menu sf-arrows" }
const _hoisted_3 = { class: "megamenu megamenu-md" }
const _hoisted_4 = {
  class: "no-gutters",
  style: {"margin":"0 !important","display":"unset !important"}
}
const _hoisted_5 = { class: "col-md-8" }
const _hoisted_6 = { class: "menu-col" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "col-md-6" }

import { computed, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { fetchCategories } from "@/repositories/admitads/categories";
import { CommonCategory } from "@/api/models/CommonCategory";
import { useApplicationStore } from "@/store/application";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainMenu',
  setup(__props) {

const route = useRoute();
const categories = ref<CommonCategory[]>([]);

const currentRoute = computed(() => route.path);
const applicationStore = useApplicationStore();

const statuses = computed(() => applicationStore.statuses);

const fetchAdmitads = async () => {
  const { data } = await fetchCategories();
  categories.value = data;
};

onMounted(async () => {
  await fetchAdmitads();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", {
        class: _normalizeClass(["megamenu-container", { active: currentRoute.value == '/' }])
      }, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "sf-with-ul"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Главная")
          ])),
          _: 1
        })
      ], 2),
      _createElementVNode("li", null, [
        (statuses.value?.offers)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: {
            name: 'AdmitadsStatic',
          },
              class: "sf-with-ul",
              style: {"display":"flex","gap":"10px"}
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Спецпредложения "),
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categories.value, (category) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: category.id
                        }, [
                          _createVNode(_component_router_link, {
                            to: `/shop/catalogue/admitads?categoryIds=${category.id}`
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(category.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("li", null, [
        _createElementVNode("a", {
          href: "https://vamprivet.ru/",
          class: "sf-with-ul",
          target: "_blank"
        }, "Акции")
      ], -1))
    ])
  ]))
}
}

})