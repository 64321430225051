export const isEmail = (event: KeyboardEvent) => {
  const char = event.key;

  const controlKeys = [
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "Tab",
    "Escape",
  ];

  if (controlKeys.includes(char)) {
    return;
  }

  const isAllowed = /^[a-zA-Z0-9@._-]$/.test(char);

  if (!isAllowed) {
    event.preventDefault();
  }
};
