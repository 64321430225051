import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pass-input-label new-pass-input-label"
}
const _hoisted_2 = { class: "new-pass-input-wrap" }
const _hoisted_3 = ["type", "placeholder", "value"]
const _hoisted_4 = {
  key: 1,
  class: "password-error"
}

import { toRefs, ref } from "vue";
import EyeIcon from "@/assets/images/icons/iconsComp/EyeIcon.vue";
import EyeCloseIcon from "@/assets/images/icons/iconsComp/EyeCloseIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PasswordInput',
  props: {
    hint: {},
    placeholder: {},
    modalValue: {},
    isValidPassword: { type: Boolean },
    enabledValidation: { type: Boolean, default: true }
  },
  emits: ["update:modalValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const passwordFieldType = ref("password");

const showPassword = () => {
  passwordFieldType.value = "text";
};

const hidePassword = () => {
  passwordFieldType.value = "password";
};

const $emit = __emit;
const { hint, modalValue, placeholder } = toRefs(props);

const updateValue = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const filteredValue = input.value.replace(/[а-яА-ЯёЁ]/g, "");
  input.value = filteredValue;
  $emit("update:modalValue", filteredValue);
};

const showEye = (target: HTMLInputElement) => {
  const length = target.value.length;
  const check = length > 0;
  target.classList.toggle("input--active", check);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", null, [
    (_unref(hint))
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(hint)), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        class: _normalizeClass(["password-input", { 'input--invalid': !_ctx.isValidPassword }]),
        name: "password repeat",
        type: passwordFieldType.value,
        placeholder: _unref(placeholder),
        value: _unref(modalValue),
        autocomplete: "password",
        onInput: [
          updateValue,
          _cache[1] || (_cache[1] = _withModifiers((e) => showEye(e.target as HTMLInputElement), ["stop"]))
        ],
        onBlur: _cache[0] || (_cache[0] = _withModifiers((e) => showEye(e.target as HTMLInputElement), ["stop"]))
      }, null, 42, _hoisted_3),
      _createElementVNode("button", {
        class: _normalizeClass(["new-pass-eye", { 'show-password--active': passwordFieldType.value === 'text' }]),
        type: "button",
        onMousedown: showPassword,
        onMouseup: hidePassword,
        onMouseleave: hidePassword,
        onTouchstart: showPassword,
        onTouchend: hidePassword
      }, [
        _createVNode(EyeIcon, { class: "new-pass-eye__eye" }),
        _createVNode(EyeCloseIcon, { class: "new-pass-eye__eye-close" })
      ], 34)
    ]),
    (_ctx.enabledValidation && !_ctx.isValidPassword && _unref(modalValue))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Недостаточно сложный пароль. "))
      : _createCommentVNode("", true)
  ]))
}
}

})