import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../../../../assets/newImg/icons/search.svg'


const _hoisted_1 = { class: "header-search header-search-extended mr-0 header-search-visible w-100" }
const _hoisted_2 = { class: "header-search-wrapper search-wrapper-wide" }
const _hoisted_3 = { class: "selected-label" }
const _hoisted_4 = {
  key: 0,
  class: "dropdown-list"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["placeholder"]

import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { useRouter, useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderSearch',
  setup(__props) {

const searchTerm = ref<string>("");
const selectedCategory = ref<"goods" | "certificates">("goods");
const inputTooltipMsg = ref("Пожалуйста, заполните это поле");

const router = useRouter();
const route = useRoute();

const isOpen = ref(false);

const dropdownOptions = [
  { value: "goods", label: "Товары" },
  { value: "certificates", label: "Сертификаты" },
];

const selectedTitle = computed(() => {
  return (
    dropdownOptions.find((o) => o.value === selectedCategory.value)?.label ?? ""
  );
});

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}

function selectOption(value: "goods" | "certificates") {
  selectedCategory.value = value;
  isOpen.value = false;
}

const placeholder = computed(() => {
  if (selectedCategory.value === "goods") {
    return "Поиск по каталогу товаров";
  } else {
    return "Поиск по каталогу сертификатов";
  }
});

async function searchProducts() {
  if (searchTerm.value.length < 2) {
    inputTooltipMsg.value = "Пожалуйста, введите минимум два символа";
    searchInput.value?.blur();
    searchInput.value?.focus();
  } else {
    await router.push({
      name: "SearchResults",
      query: {
        search: searchTerm.value,
        category: selectedCategory.value,
      },
    });
    searchTerm.value = "";
    searchInput.value?.blur();
  }
}

function showSearchForm() {
  document.querySelector(".header .header-search")?.classList.add("show");
}

function closeSearchForm() {
  document.querySelector(".header .header-search")?.classList.remove("show");
}

const searchInput = ref<HTMLInputElement | null>(null);

const dropdownRef = ref<HTMLElement | null>(null);

function handleClickOutside(event: MouseEvent) {
  const dropdownEl = dropdownRef.value;
  if (dropdownEl && !dropdownEl.contains(event.target as Node)) {
    isOpen.value = false;
  }
}

onMounted(() => {
  document.querySelector("body")?.addEventListener("click", closeSearchForm);
  document.addEventListener("click", handleClickOutside);

  if (route.query.category) {
    const cat = route.query.category;
    if (cat === "goods" || cat === "certificates") {
      selectedCategory.value = cat;
    }
  }
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onClick: _withModifiers(showSearchForm, ["stop"]),
      onSubmit: _withModifiers(searchProducts, ["prevent"]),
      class: "w-100"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          ref_key: "dropdownRef",
          ref: dropdownRef,
          class: _normalizeClass(["search-category", { 'is-open': isOpen.value }]),
          onClick: _withModifiers(toggleDropdown, ["stop"])
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(selectedTitle.value), 1),
          (isOpen.value)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(dropdownOptions, (option) => {
                  return _createElementVNode("li", {
                    key: option.value,
                    onClick: _withModifiers(($event: any) => (
                selectOption(option.value as 'goods' | 'certificates')
              ), ["stop"])
                  }, _toDisplayString(option.label), 9, _hoisted_5)
                }), 64))
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _cache[1] || (_cache[1] = _createElementVNode("label", {
          for: "q",
          class: "sr-only"
        }, "Search", -1)),
        _withDirectives(_createElementVNode("input", {
          placeholder: placeholder.value,
          ref_key: "searchInput",
          ref: searchInput,
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchTerm).value = $event))
        }, null, 8, _hoisted_6), [
          [_vModelText, searchTerm.value],
          [
            _directive_tooltip,
            {
            value: inputTooltipMsg.value,
            class: 'custom-tooltip',
            disabled: false,
          },
            void 0,
            {
              focus: true,
              bottom: true
            }
          ]
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("button", {
          class: "ml-3",
          type: "submit"
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          })
        ], -1))
      ])
    ], 32)
  ]))
}
}

})