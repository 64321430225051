<template>
  <div class="flex tw-whitespace-nowrap">
    <span class="tw-whitespace-nowrap">{{
      formatNumber(Number(price!.toFixed(2)))
    }}</span
    >&nbsp;<span class="tw-whitespace-nowrap">{{ priceName }}</span>
  </div>
</template>

<script setup lang="ts">
import { defineProps, toRef, withDefaults } from "vue";
import { numeral } from "@/utilities/number";

const props = withDefaults(
  defineProps<{ price: number; priceName?: string }>(),
  {
    price: 0,
  }
);
const price = toRef(props, "price");
const priceName = toRef(props, "priceName");
const formatNumber = (input) => {
  const num = typeof input === "number" ? input : parseFloat(input);

  let formattedNumber;
  if (num >= 10000) {
    formattedNumber = numeral(num).format("0,0.00").replace(/,/g, " ");
  } else {
    formattedNumber = numeral(num).format("0.00");
  }

  return formattedNumber;
};
</script>

<style scoped></style>
