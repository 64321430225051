import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../../assets/newImg/icons/modal-close.svg'


import { NCard } from "naive-ui";
import { NModal } from "naive-ui";
import { ref, computed } from "vue";
import { useCalendarStore } from "@/store/calendar";


export default /*@__PURE__*/_defineComponent({
  __name: 'vdatepicker',
  setup(__props) {

const store = useCalendarStore();
const showModal = computed(() => store.shCalendar);

const togCalendar = () => {
  store.toggleCalendar();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(NModal), {
    show: showModal.value,
    "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(NCard), {
        style: {"width":"300px"},
        title: "",
        bordered: false,
        size: "huge",
        role: "dialog",
        "aria-modal": "true"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            onClick: togCalendar,
            style: {"cursor":"pointer"},
            class: "close_btn ml-auto"
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "modal"
            }, null, -1)
          ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})