import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/newImg/icons/w_arrow-right.svg'


const _hoisted_1 = { class: "input-group input-group-round" }
const _hoisted_2 = { class: "input-group-append" }

import { ref } from "vue";
import { useInput } from "@/composables/useInput";
import { subscribe } from "@/repositories/subscription";
import { isEmail } from "@/utilities/isEmail";
import { useApplicationStore } from "@/store/application";
import { useAuth } from "@/composables/useAuth";


export default /*@__PURE__*/_defineComponent({
  __name: 'SpecialOffers',
  setup(__props) {

const { email, isValidEmail } = useInput();
const { toggleToast, checkAuth } = useAuth();

const showRequiredTooltip = ref(false);
const applicationStore = useApplicationStore();

const emailTooltip = ref<HTMLElement | null>(null);

const onClickSubscribe = checkAuth(async () => {
  try {
    if (!email.value || !isValidEmail.value) {
      showRequiredTooltip.value = true;
      emailTooltip.value?.dispatchEvent(new Event("mouseenter"));
      return;
    }
    applicationStore.showLoader();
    await subscribe(email.value);
    toggleToast({
      type: "success",
      message: "Вы успешно подписались на рассылку",
    });
    email.value = "";
  } catch (error) {
    toggleToast({
      type: "error",
      message: "Произошла ошибка при подписке на рассылку",
    });
  } finally {
    applicationStore.hideLoader();
  }
});

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "cta-heading text-center" }, [
      _createElementVNode("h3", { class: "cta-title text-white" }, "Получайте специальные предложения"),
      _createElementVNode("p", { class: "cta-desc text-white" }, "подпишитесь на нашу рассылку")
    ], -1)),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          ref_key: "emailTooltip",
          ref: emailTooltip,
          name: "email",
          type: "email",
          placeholder: "Введите эл. почту",
          class: _normalizeClass(["form-control form-control-white", { 'input--invalid': !_unref(isValidEmail) }]),
          "aria-label": "Email Adress",
          onKeypress: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(isEmail) && _unref(isEmail)(...args))),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(email) ? (email).value = $event : null))
        }, null, 34), [
          [
            _directive_tooltip,
            {
          value: 'Пожалуйста, заполните это поле',
          class: 'custom-tooltip',
          disabled: !showRequiredTooltip.value,
          autoHide: false,
        },
            void 0,
            { bottom: true }
          ],
          [_vModelText, _unref(email)]
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "btn btn-primary",
            type: "submit",
            style: {"display":"flex","gap":"5px"},
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(onClickSubscribe) && _unref(onClickSubscribe)(...args)))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("span", { style: {"display":"flex","gap":"5px","color":"#fff"} }, [
              _createElementVNode("span", { style: {"height":"16px"} }, "Подписаться"),
              _createElementVNode("img", {
                src: _imports_0,
                alt: "w_arrow"
              })
            ], -1)
          ]))
        ])
      ])
    ])
  ], 64))
}
}

})