import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { toRefs } from "vue";
import PhoneBookIcon from "@/assets/images/icons/iconsComp/PhoneBookIcon.vue";
import UserBookIcon from "@/assets/images/icons/iconsComp/UserBookIcon.vue";

type Tab = "phone" | "code";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthTabs',
  props: {
    modalValue: {}
  },
  emits: ["update:modalValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const { modalValue } = toRefs(props);
const $emit = __emit;

const updateValue = (val: Tab) => {
  $emit("update:modalValue", val);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'remind-pass-switch-wrap',
      _unref(modalValue) === 'phone' ? 'remind-pass-switch-wrap_mb' : '',
      _unref(modalValue) === 'code' ? 'remind-pass-switch-wrap_member' : '',
    ])
  }, [
    _createElementVNode("button", {
      class: _normalizeClass([
        'remind-pass-switch',
        _unref(modalValue) === 'phone' ? 'remind-pass-switch_primary' : '',
      ]),
      type: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (updateValue('phone')))
    }, [
      _createVNode(PhoneBookIcon),
      _cache[2] || (_cache[2] = _createElementVNode("span", null, "По номеру телефона", -1))
    ], 2),
    _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "remind-pass-switch-divider" }, null, -1)),
    _createElementVNode("button", {
      class: _normalizeClass([
        'remind-pass-switch',
        _unref(modalValue) === 'code' ? 'remind-pass-switch_primary' : '',
      ]),
      type: "button",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (updateValue('code')))
    }, [
      _createVNode(UserBookIcon),
      _cache[3] || (_cache[3] = _createElementVNode("span", null, "По номеру участника", -1))
    ], 2)
  ], 2))
}
}

})