<template>
  <Teleport to="body">
    <div class="toggled" id="wrapper">
      <div class="btn btn-go-top">
        <i class="fas fa-arrow-up pt-2 mt-1"></i>
      </div>
      <div class="background"></div>
      <div class="loader-big" style="opacity: 0.496607">
        <div class="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts"></script>

<style scoped>
.loader-big {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0px;
  background: black;
  z-index: 1051;
  opacity: 0.5;
}

.loader-big .lds-grid {
  top: 50%;
  left: 50%;
  margin-top: -32px;
  margin-left: -32px;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.toggled {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0px;
  z-index: 1000;
}
</style>
