import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container for-you" }
const _hoisted_2 = { class: "heading heading-flex mb-3" }
const _hoisted_3 = { class: "heading-left" }
const _hoisted_4 = { class: "heading-right" }
const _hoisted_5 = { class: "products" }
const _hoisted_6 = { class: "row justify-content-center" }

import { toRef } from "vue";
import { GoodProduct } from "@/api/models/GoodProduct";
import RecomendationCard from "../../elements/products/RecomendationCard.vue";
import { CommonCategory } from "@/api/models/CommonCategory";


export default /*@__PURE__*/_defineComponent({
  __name: 'RecommendCollection',
  props: {
    products: {}
  },
  setup(__props: any) {

const props = __props;

const products = toRef(props, "products");
const labelCollections = [
  {
    top: false,
    new: false,
    offer: false,
  },
  {
    top: false,
    new: false,
    offer: false,
  },
  {
    top: true,
    new: false,
    offer: true,
  },
  {
    top: false,
    new: true,
    offer: true,
  },
  {
    top: true,
    new: true,
    offer: false,
  },
  {
    top: true,
    new: false,
    offer: false,
  },
  {
    top: true,
    new: true,
    offer: false,
  },
  {
    top: true,
    new: false,
    offer: false,
  },
];

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: {
            name: 'GoodsStatic',
            query: {
              specialFilter: 'Popular',
            },
          } }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("h2", { class: "title" }, "Рекомендации для Вас", -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          to: {
            name: 'GoodsStatic',
            query: {
              specialFilter: 'Popular',
            },
          },
          class: "title-link"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Смотреть все предложения "),
            _createElementVNode("svg", {
              width: "16",
              height: "16",
              viewBox: "0 0 16 16",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M1 7.9999C1 7.86729 1.05268 7.74011 1.14645 7.64635C1.24021 7.55258 1.36739 7.4999 1.5 7.4999H13.293L10.146 4.3539C10.0521 4.26001 9.99937 4.13267 9.99937 3.9999C9.99937 3.86712 10.0521 3.73979 10.146 3.6459C10.2399 3.55201 10.3672 3.49927 10.5 3.49927C10.6328 3.49927 10.7601 3.55201 10.854 3.6459L14.854 7.6459C14.9006 7.69234 14.9375 7.74752 14.9627 7.80827C14.9879 7.86901 15.0009 7.93413 15.0009 7.9999C15.0009 8.06567 14.9879 8.13079 14.9627 8.19153C14.9375 8.25228 14.9006 8.30745 14.854 8.3539L10.854 12.3539C10.7601 12.4478 10.6328 12.5005 10.5 12.5005C10.3672 12.5005 10.2399 12.4478 10.146 12.3539C10.0521 12.26 9.99937 12.1327 9.99937 11.9999C9.99937 11.8671 10.0521 11.7398 10.146 11.6459L13.293 8.4999H1.5C1.36739 8.4999 1.24021 8.44722 1.14645 8.35345C1.05268 8.25968 1 8.13251 1 7.9999Z",
                fill: "#777777"
              })
            ], -1)
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(products.value, (product, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-6 col-md-4 col-lg-3",
            key: index
          }, [
            _createVNode(RecomendationCard, {
              product: product,
              labels: labelCollections[index]
            }, null, 8, ["product", "labels"])
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})