<template>
  <div
    class="header-search header-search-extended mr-0 header-search-visible w-100"
  >
    <form
      @click.stop="showSearchForm"
      @submit.prevent="searchProducts"
      class="w-100"
    >
      <div class="header-search-wrapper search-wrapper-wide">
        <div
          ref="dropdownRef"
          class="search-category"
          :class="{ 'is-open': isOpen }"
          @click.stop="toggleDropdown"
        >
          <div class="selected-label">
            {{ selectedTitle }}
          </div>

          <ul v-if="isOpen" class="dropdown-list">
            <li
              v-for="option in dropdownOptions"
              :key="option.value"
              @click.stop="
                selectOption(option.value as 'goods' | 'certificates')
              "
            >
              {{ option.label }}
            </li>
          </ul>
        </div>
        <label for="q" class="sr-only">Search</label>
        <input
          :placeholder="placeholder"
          ref="searchInput"
          type="text"
          class="form-control"
          v-model="searchTerm"
          v-tooltip.focus.bottom="{
            value: inputTooltipMsg,
            class: 'custom-tooltip',
            disabled: false,
          }"
        />
        <button class="ml-3" type="submit">
          <img src="../../../../assets/newImg/icons/search.svg" alt="" />
        </button>
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { useRouter, useRoute } from "vue-router";

const searchTerm = ref<string>("");
const selectedCategory = ref<"goods" | "certificates">("goods");
const inputTooltipMsg = ref("Пожалуйста, заполните это поле");

const router = useRouter();
const route = useRoute();

const isOpen = ref(false);

const dropdownOptions = [
  { value: "goods", label: "Товары" },
  { value: "certificates", label: "Сертификаты" },
];

const selectedTitle = computed(() => {
  return (
    dropdownOptions.find((o) => o.value === selectedCategory.value)?.label ?? ""
  );
});

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}

function selectOption(value: "goods" | "certificates") {
  selectedCategory.value = value;
  isOpen.value = false;
}

const placeholder = computed(() => {
  if (selectedCategory.value === "goods") {
    return "Поиск по каталогу товаров";
  } else {
    return "Поиск по каталогу сертификатов";
  }
});

async function searchProducts() {
  if (searchTerm.value.length < 2) {
    inputTooltipMsg.value = "Пожалуйста, введите минимум два символа";
    searchInput.value?.blur();
    searchInput.value?.focus();
  } else {
    await router.push({
      name: "SearchResults",
      query: {
        search: searchTerm.value,
        category: selectedCategory.value,
      },
    });
    searchTerm.value = "";
    searchInput.value?.blur();
  }
}

function showSearchForm() {
  document.querySelector(".header .header-search")?.classList.add("show");
}

function closeSearchForm() {
  document.querySelector(".header .header-search")?.classList.remove("show");
}

const searchInput = ref<HTMLInputElement | null>(null);

const dropdownRef = ref<HTMLElement | null>(null);

function handleClickOutside(event: MouseEvent) {
  const dropdownEl = dropdownRef.value;
  if (dropdownEl && !dropdownEl.contains(event.target as Node)) {
    isOpen.value = false;
  }
}

onMounted(() => {
  document.querySelector("body")?.addEventListener("click", closeSearchForm);
  document.addEventListener("click", handleClickOutside);

  if (route.query.category) {
    const cat = route.query.category;
    if (cat === "goods" || cat === "certificates") {
      selectedCategory.value = cat;
    }
  }
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style scoped>
.search-category {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  width: auto;
  padding: 4px;
}

.selected-label {
  background: #f5f8fb;
  border-radius: 10px;
  padding: 0 36px 0 12px;
  font-size: 14px;
  display: flex;
  height: 100%;
  line-height: normal;
  align-items: center;
  justify-content: center;
}

.search-category::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 12px;
  height: 8px;
  transform: translateY(-50%) rotate(0deg);
  transition: transform 0.3s ease;
  pointer-events: none;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="12" height="8" viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l5 5 5-5" stroke="%23666" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  margin: 4px 0 0;
  padding: 8px 0;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 9999;
}

.dropdown-list li {
  padding: 8px 16px;
  font-size: 14px;
  white-space: nowrap;
}

.dropdown-list li:hover {
  color: var(--primary_bg);
}

.search-category.is-open::after {
  transform: translateY(-50%) rotate(180deg);
}

@media screen and (max-width: 768px) {
  .form-control {
    padding-right: 0 !important;
  }
}
</style>
