import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pass-container" }
const _hoisted_2 = { class: "pass-wrap remind-pass-wrap" }

import { toRefs, computed } from "vue";
import Modal from "@/components/elements/Modal.vue";
import PhoneInput from "@/components/elements/inputs/PhoneInput.vue";
import PasswordInput from "@/components/elements/inputs/PasswordInput.vue";
import NumberMemberInput from "@/components/elements/inputs/NumberMemberInput.vue";
import AuthBtn from "@/components/elements/buttons/AuthBtn.vue";
import AuthTabs from "@/components/elements/AuthTabs.vue";
import { useInput } from "@/composables/useInput";
import ErrorMsg from "@/components/elements/form/ErrorMsg.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginModal',
  props: {
    open: { type: Boolean }
  },
  emits: [
  "close",
  "showRegistration",
  "showRemind",
  "sendSignIn",
],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const {
  currentTab,
  phone,
  memberNumber,
  password,
  isValidPhone,
  currentName,
  isValidMemberNumber,
  clearInputs,
  clearErrorMsg,
  errorMessage,
} = useInput();

const { open } = toRefs(props);

const $emit = __emit;

const onRemindPassword = () => {
  $emit("showRemind");
  clearInputs();
};

const onRegistration = () => {
  $emit("showRegistration");
  clearInputs();
};

const onHandleClose = () => {
  clearInputs();
  $emit("close");
};

const onHandleSubmit = () => {
  signIn();
};

const signIn = async () => {
  $emit(
    "sendSignIn",
    {
      userName: currentName.value,
      password: password.value,
    },
    onHandleClose
  );
};

const disabledSignIn = computed(() => {
  return (
    (currentTab.value === "phone" && !phone.value) ||
    (currentTab.value === "code" && !memberNumber.value) ||
    !password.value ||
    (currentTab.value === "code" && !isValidMemberNumber.value) ||
    (currentTab.value === "phone" && !isValidPhone.value)
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    open: _unref(open),
    onClose: onHandleClose,
    customClass: "login-modal"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "mb-16px" }, "Вход", -1)),
          _createVNode(AuthTabs, {
            "modal-value": _unref(currentTab),
            "onUpdate:modalValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(currentTab) ? (currentTab).value = $event : null))
          }, null, 8, ["modal-value"]),
          _createElementVNode("form", {
            class: "w-100",
            onSubmit: _withModifiers(onHandleSubmit, ["prevent","stop"])
          }, [
            (_unref(errorMessage))
              ? (_openBlock(), _createBlock(ErrorMsg, {
                  key: 0,
                  errorMsg: _unref(errorMessage),
                  onClear: _unref(clearErrorMsg)
                }, null, 8, ["errorMsg", "onClear"]))
              : _createCommentVNode("", true),
            (_unref(currentTab) === 'phone')
              ? (_openBlock(), _createBlock(PhoneInput, {
                  key: 1,
                  class: "mb-16px",
                  isValidPhone: _unref(isValidPhone),
                  modelValue: _unref(phone),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(phone) ? (phone).value = $event : null))
                }, null, 8, ["isValidPhone", "modelValue"]))
              : _createCommentVNode("", true),
            (_unref(currentTab) === 'code')
              ? (_openBlock(), _createBlock(NumberMemberInput, {
                  key: 2,
                  placeholder: "00000000000",
                  hint: "Индивидуальный номер Клиента Банка Новиком",
                  class: "mb-16px",
                  isValidMemberNumber: _unref(isValidMemberNumber),
                  modelValue: _unref(memberNumber),
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(memberNumber) ? (memberNumber).value = $event : null))
                }, null, 8, ["isValidMemberNumber", "modelValue"]))
              : _createCommentVNode("", true),
            _createVNode(PasswordInput, {
              placeholder: "Пароль",
              isValidPassword: true,
              "enabled-validation": false,
              class: "mb-8px",
              "modal-value": _unref(password),
              "onUpdate:modalValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(password) ? (password).value = $event : null))
            }, null, 8, ["modal-value"]),
            _createElementVNode("button", {
              type: "button",
              class: "forget-password mb-16px",
              onClick: onRemindPassword
            }, " Забыли пароль? "),
            _createVNode(AuthBtn, {
              title: "Войти",
              type: "submit",
              disabled: disabledSignIn.value
            }, null, 8, ["disabled"]),
            _createElementVNode("div", { class: "link-sign-in" }, [
              _cache[4] || (_cache[4] = _createTextVNode(" Нет аккаунта? ")),
              _createElementVNode("span", { onClick: onRegistration }, "Регистрация")
            ])
          ], 32)
        ])
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}
}

})