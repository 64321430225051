<template>
  <svg
    class="arrow-down"
    width="11"
    height="7"
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.68359 1.33331L5.51693 5.41665L1.43359 1.33331"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script setup lang="ts">
import { defineProps, toRefs } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "#797979",
  },
});

const { color } = toRefs(props);
</script>
