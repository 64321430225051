<template>
  <div class="container">
    <div class="heading text-center mb-3">
      <h2 class="title">Популярные направления из Москвы</h2>
      <p class="title-desc">Предложения дня</p>
    </div>
    <div class="row">
      <div class="col-lg-6 deal-col">
        <div class="deal deal1">
          <div class="deal-top">
            <h2 style="font-family: Gilroy-Medium !important">
              Санкт-Петербург
            </h2>
            <h4>Авиабилеты</h4>
          </div>
          <div class="deal-content">
            <h3 class="product-title">
              <router-link
                :to="{
                  name: 'FlightSearch',
                  query: queriesSaintPetersburg,
                }"
                style="font-family: Gilroy-Medium !important"
                >Туда - обратно</router-link
              >
            </h3>
            <div class="product-price">
              <PriceFormatted
                class="new-price"
                style="font-family: Gilroy-Medium !important"
                :price="minPriceSaintPetersburg"
                priceName="бонусов"
              />
            </div>
            <router-link
              :to="{
                name: 'FlightSearch',
                query: queriesSaintPetersburg,
              }"
              class="btn btn-link"
              style="gap: 5px; margin-top: 40px"
            >
              <span style="font-family: Gilroy-Medium !important"
                >Бронировать</span
              >
              <img
                src="../../../assets/newImg/icons/bi_arrow-right.svg"
                alt=""
              />
              <!-- <i class="icon-long-arrow-right"></i> -->
            </router-link>
          </div>
          <div class="deal-bottom">
            <!-- <count-down
              wrap="deal-countdown daily-deal-countdown"
              until="+10h"
              format="HMS"
            ></count-down> -->
          </div>
        </div>
      </div>
      <div class="col-lg-6 deal-col">
        <div class="deal deal2">
          <div class="deal-top">
            <h2 style="font-family: Gilroy-Medium !important">Калининград</h2>
            <h4>Авиабилеты</h4>
          </div>
          <div class="deal-content">
            <h3 class="product-title">
              <router-link
                :to="{
                  name: 'FlightSearch',
                  query: queriesKaliningrad,
                }"
                >В одну сторону</router-link
              >
            </h3>
            <div class="product-price">
              <PriceFormatted
                :price="minPriceKaliningrad"
                price-name="бонусов"
                class="new-price"
              />
            </div>
            <router-link
              :to="{
                name: 'FlightSearch',
                query: queriesKaliningrad,
              }"
              class="btn btn-link"
              style="gap: 5px; margin-top: 40px"
            >
              <span style="font-family: Gilroy-Medium !important"
                >Бронировать</span
              >
              <img
                src="../../../assets/newImg/icons/bi_arrow-right.svg"
                alt=""
              />
              <!-- <i class="icon-long-arrow-right"></i> -->
            </router-link>
          </div>
          <div class="deal-bottom">
            <!-- <count-down wrap="deal-countdown daily-deal-countdown" until="+11h" format="HMS"></count-down> -->
          </div>
        </div>
      </div>
    </div>
    <div class="more-container text-center mt-1 mb-5">
      <router-link
        :to="{
          name: 'Travels',
        }"
        class="btn btn-outline-dark-2 btn-round btn-more"
        style="
          gap: 5px;
          font-family: Gilroy-Medium !important;
          font-weight: 600;
        "
      >
        <span style="text-transform: none !important; height: 16px"
          >Смотреть все направления</span
        >
        <img src="../../../assets/newImg/icons/bl_arrow-right.svg" alt="" />
        <!-- <i class="icon-long-arrow-right"></i> -->
      </router-link>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps, toRefs } from "vue";
import PriceFormatted from "@/components/elements/typography/PriceFormatted.vue";
import { formatDate } from "@/utilities/date";

const props = defineProps<{
  minPriceSaintPetersburg: number;
  minPriceKaliningrad: number;
}>();

const { minPriceSaintPetersburg, minPriceKaliningrad } = toRefs(props);
const dateFrom = formatDate(
  new Date(new Date().setDate(new Date().getDate() + 7))
);
const dateTo = formatDate(
  new Date(new Date().setDate(new Date().getDate() + 10))
);
const queriesSaintPetersburg = {
  departure: JSON.stringify({
    id: "Москва-MOW",
    label: "Москва, любой аэропорт",
    value: "MOW",
    format: "RU",
  }),
  arrival: JSON.stringify({
    id: "Пулково-LED",
    label: "Санкт-Петербург, Пулково",
    value: "LED",
    format: "Россия",
  }),
  sort: "price",
  sortDirection: "Asc",
  dateFrom,
  dateTo,
  adt: 1,
  currentTab: "avias",
};
const queriesKaliningrad = {
  departure: JSON.stringify({
    id: "Москва-MOW",
    label: "Москва, любой аэропорт",
    value: "MOW",
    format: "RU",
  }),
  arrival: JSON.stringify({
    id: "Калининград-KGD",
    label: "Калининград, любой аэропорт",
    value: "KGD",
    format: "Россия",
  }),
  sort: "price",
  sortDirection: "Asc",
  dateFrom,
  currentTab: "avias",
};
</script>
