<template>
  <div class="text-truncate d-flex-important align-items-center icon-wrapper">
    <ArrowDownIcon
      class="arrow-down-icon"
      :class="{ rotate: isIconRotated }"
      color="#55246a"
    />
    <svg
      class="balance-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6033 9.67607C13.2707 9.67607 15.4413 7.50541 15.4413 4.83804C15.4413 2.17067 13.2707 0 10.6033 0C7.93592 0 5.76526 2.17067 5.76526 4.83804C5.76526 7.50541 7.93592 9.67607 10.6033 9.67607ZM10.6033 1.93521C12.2031 1.93521 13.5061 3.23826 13.5061 4.83804C13.5061 6.43782 12.2031 7.74086 10.6033 7.74086C9.00352 7.74086 7.70047 6.43782 7.70047 4.83804C7.70047 3.23826 9.00352 1.93521 10.6033 1.93521Z"
        fill="currentColor"
      />
      <path
        d="M2.11089 19.9908H2.12056C2.35602 20.0263 2.59792 19.9585 2.79789 19.8037C3.01076 19.6392 3.14945 19.3973 3.17848 19.1361C3.49134 16.3171 5.86521 14.1916 8.70352 14.1916H12.503C15.3413 14.1916 17.7152 16.3171 18.028 19.1361C18.0829 19.6263 18.4957 19.9972 18.9892 19.9972C19.0214 19.9972 19.0569 19.9972 19.0892 19.9908C19.3537 19.9617 19.5827 19.836 19.7439 19.6328C19.9052 19.4296 19.9794 19.178 19.9503 18.9232C19.5278 15.1237 16.3283 12.2564 12.503 12.2564H8.70352C4.87825 12.2564 1.67547 15.1237 1.25617 18.9232C1.22714 19.1812 1.30132 19.4328 1.46259 19.6328C1.62386 19.836 1.85609 19.9617 2.11089 19.9908Z"
        fill="currentColor"
      />
    </svg>
    {{ name }}: <PriceFormatted :price="Number(balance)" price-name="бонусов" />
  </div>
</template>
<script lang="ts" setup>
import { defineProps, toRefs, withDefaults } from "vue";
import PriceFormatted from "@/components/elements/typography/PriceFormatted.vue";
import ArrowDownIcon from "@/assets/images/icons/iconsComp/ArrowDownIcon.vue";

const props = withDefaults(
  defineProps<{
    name: string;
    balance: string;
    isIconRotated: boolean;
  }>(),
  {
    balance: "0",
    name: "",
    isIconRotated: false,
  }
);
const { name, balance, isIconRotated } = toRefs(props);
</script>
<style lang="scss" scoped>
.text-truncate {
  display: flex;
  gap: 5px;
  font-family: Gilroy-Medium !important;
  color: #55246a;
}
</style>
<style>
.arrow-down-icon {
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}

.icon-wrapper:hover .arrow-down-icon {
  transform: rotate(180deg);
}
</style>
