import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "arrow-down",
  width: "11",
  height: "7",
  viewBox: "0 0 11 7",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["stroke"]

import { toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ArrowDownIcon',
  props: {
  color: {
    type: String,
    default: "#797979",
  },
},
  setup(__props) {

const props = __props;

const { color } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M9.68359 1.33331L5.51693 5.41665L1.43359 1.33331",
      stroke: _unref(color),
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2)
  ]))
}
}

})