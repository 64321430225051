import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none"
}
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["stroke"]

import { toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ClearBtn',
  props: {
    color: { default: "#797979" }
  },
  emits: ["clear"],
  setup(__props: any, { emit: __emit }) {

const $emit = __emit;

const props = __props;
const { color } = toRefs(props);

const onClick = (event: Event) => {
  event.preventDefault();
  $emit("clear");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: "btn-clear",
    onClick: onClick
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("path", {
        d: "M5.83398 14.0834L14.084 5.83337",
        stroke: _unref(color),
        "stroke-width": "1.5",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }, null, 8, _hoisted_2),
      _createElementVNode("path", {
        d: "M14.084 14.0834L5.83398 5.83337",
        stroke: _unref(color),
        "stroke-width": "1.5",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }, null, 8, _hoisted_3)
    ]))
  ]))
}
}

})