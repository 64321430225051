import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pass-container" }
const _hoisted_2 = { class: "pass-wrap remind-pass-wrap" }

import { toRefs, computed } from "vue";
import Modal from "@/components/elements/Modal.vue";
import PhoneInput from "@/components/elements/inputs/PhoneInput.vue";
import AuthBtn from "@/components/elements/buttons/AuthBtn.vue";
import NumberMemberInput from "@/components/elements/inputs/NumberMemberInput.vue";
import AuthTabs from "@/components/elements/AuthTabs.vue";
import ErrorMsg from "@/components/elements/form/ErrorMsg.vue";
import { useInput } from "@/composables/useInput";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegistrationModal',
  props: {
    open: { type: Boolean }
  },
  emits: ["close", "showLogin", "registration"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { open } = toRefs(props);
const {
  isValidPhone,
  isValidMemberNumber,
  phone,
  memberNumber,
  currentTab,
  clearInputs,
  clearErrorMsg,
  errorMessage,
} = useInput();

const $emit = __emit;

const onHandleClose = () => {
  clearInputs();
  $emit("close");
};

const registration = () => {
  $emit(
    "registration",
    currentTab.value,
    currentTab.value === "phone" ? phone.value : memberNumber.value,
    "registration",
    clearInputs
  );
};

const disabledRegistration = computed(() => {
  return (
    (currentTab.value === "phone" && phone.value === "+7") ||
    (currentTab.value === "code" && !memberNumber.value) ||
    (currentTab.value === "phone" && !isValidPhone.value) ||
    (currentTab.value === "code" && !isValidMemberNumber.value)
  );
});

const showLogin = () => {
  $emit("showLogin");
  clearInputs();
};

const onHandleSubmit = () => {
  console.log("submit");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    open: _unref(open),
    onClose: onHandleClose,
    "custom-class": "registration-modal"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Регистрация", -1)),
          _createVNode(AuthTabs, {
            "modal-value": _unref(currentTab),
            "onUpdate:modalValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(currentTab) ? (currentTab).value = $event : null))
          }, null, 8, ["modal-value"]),
          _createElementVNode("form", {
            class: "w-100",
            onSubmit: _withModifiers(onHandleSubmit, ["prevent"])
          }, [
            (_unref(errorMessage))
              ? (_openBlock(), _createBlock(ErrorMsg, {
                  key: 0,
                  errorMsg: _unref(errorMessage),
                  onClear: _unref(clearErrorMsg)
                }, null, 8, ["errorMsg", "onClear"]))
              : _createCommentVNode("", true),
            (_unref(currentTab) === 'phone')
              ? (_openBlock(), _createBlock(PhoneInput, {
                  key: 1,
                  title: "Для регистрации введите номер мобильного телефона. Код будет отправлен на этот номер.",
                  isValidPhone: _unref(isValidPhone),
                  modelValue: _unref(phone),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(phone) ? (phone).value = $event : null))
                }, null, 8, ["isValidPhone", "modelValue"]))
              : _createCommentVNode("", true),
            (_unref(currentTab) === 'code')
              ? (_openBlock(), _createBlock(NumberMemberInput, {
                  key: 2,
                  placeholder: "00000000000",
                  hint: "Индивидуальный номер Клиента Банка Новиком",
                  title: "Для того чтобы установить или изменить свой пароль, пожалуйста, введите ваш номер участника бонусной программы. Код будет направлен на ваш номер телефона.",
                  isValidMemberNumber: _unref(isValidMemberNumber),
                  modelValue: _unref(memberNumber),
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(memberNumber) ? (memberNumber).value = $event : null))
                }, null, 8, ["isValidMemberNumber", "modelValue"]))
              : _createCommentVNode("", true),
            _createVNode(AuthBtn, {
              title: "Получить код",
              type: "submit",
              outlined: "",
              class: "mb-40px",
              disabled: disabledRegistration.value,
              onClick: registration
            }, null, 8, ["disabled"]),
            _createElementVNode("div", { class: "link-sign-in" }, [
              _cache[3] || (_cache[3] = _createTextVNode(" Есть аккаунт? ")),
              _createElementVNode("span", { onClick: showLogin }, "Вход")
            ])
          ], 32)
        ])
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}
}

})