import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-area-widget w-100" }
const _hoisted_2 = { class: "p-area-widget-title text-left" }

import { toRefs } from "vue";
import ClearBtn from "@/components/elements/buttons/ClearBtn.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorMsg',
  props: {
    errorMsg: {}
  },
  emits: ["clear"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { errorMsg } = toRefs(props);
const $emit = __emit;

const clearErrorMsg = () => {
  $emit("clear");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_unref(errorMsg)), 1)
    ]),
    _createVNode(ClearBtn, {
      onClear: clearErrorMsg,
      class: "input-clear"
    })
  ]))
}
}

})