import { AviaStation } from "@/api/models/Station";
import api from "@/repositories/repository";
import {
  DocType,
  AviaSearch,
  BookingAviaParams,
  BookingPayParams,
  AcceptPaymentAviaParams,
  OrderResponseAvia,
} from "@/types";

export const fetchSuggestAviaStation = async (
  search: string
): Promise<{ data: AviaStation[] }> => {
  const { data } = await api.get(`/api/avia/ports?search=${search}`);
  return data;
};

export const fetchSearchAvia = async (
  queryString: string
): Promise<{
  items: {
    data: AviaSearch[];
    page: number;
    perPage: number;
    pages: number;
    total: number;
  };
  searchId: number;
}> => {
  const { data } = await api.get(`/api/avia/search?${queryString}`);
  return data;
};

export const fetchDocTypes = async (): Promise<DocType[]> => {
  const { data } = await api.get(`/api/avia/doc-types`);
  return data;
};

export const makeBookingAvia = async (
  params: BookingAviaParams
): Promise<{ orderId: number }> => {
  const { data } = await api.post(`/api/avia/book`, params);
  return data;
};

export const doPayAvia = async (
  params: BookingPayParams
): Promise<OrderResponseAvia> => {
  const { data } = await api.post(`/api/avia/do-pay`, params);
  return data;
};

export const acceptPaymentAvia = async (params: AcceptPaymentAviaParams) => {
  const { data } = await api.post(`/api/avia/accept-payment`, params);
  return data;
};

export const fetchAviaInfo = async (
  id: string
): Promise<{
  conditional: Array<{
    arrival: null;
    cancellation: null;
    change: null;
    departure: null;
    text: string;
  }>;
}> => {
  const url = `/api/avia/info?id=${id}`;
  const { data } = await api.get(url);

  return data;
};
