import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import loginVue from "@/views/pages/login.vue";
import { useAuth } from "@/composables/useAuth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/sign_up",
    name: "sign up",
    component: loginVue,
  },
  {
    path: "/Merchant/OneTouchAuth",
    name: "OneTouchAuth",
    component: () => import("@/views/auth/OneTouchAuth.vue"),
  },
  // {
  //   path: "/password",
  //   name: "Password",
  //   component: Password,
  // },
  // {
  //   path: "/remind-password",
  //   name: "Remind-Password",
  //   component: RemindPassword,
  // },
  {
    path: "/shop/catalogue/goods",
    name: "GoodsStatic",
    component: () => import("@/views/shop/Goods.vue"),
  },
  {
    path: "/search-result",
    name: "SearchResults",
    component: () => import("@/views/shop/SearchResults.vue"),
  },
  {
    path: "/shop/good/:id",
    name: "Good",
    component: () => import("@/views/product/Good.vue"),
  },
  {
    path: "/shop/catalogue/certificates",
    name: "CertificatesStatic",
    component: () => import("@/views/shop/Certificates.vue"),
  },
  {
    path: "/shop/catalogue/certificate/:id",
    name: "Certificate",
    component: () => import("@/views/product/Certificate.vue"),
  },
  {
    path: "/shop/catalogue/admitads",
    name: "AdmitadsStatic",
    component: () => import("@/views/shop/Admitads.vue"),
  },
  {
    path: "/shop/catalogue/admitad/:id",
    name: "Admitad",
    component: () => import("@/views/product/Admitad.vue"),
  },
  {
    path: "/shop/catalogue/charities",
    name: "Charities",
    component: () => import("@/views/shop/Charities.vue"),
  },
  {
    path: "/product/default/:slug",
    name: "product show",
    component: () => import("@/views/product/centered/_slug.vue"),
  },
  {
    path: "/product/default",
    name: "product default",
    component: () => import("@/views/product/centered/_slug.vue"),
  },
  {
    path: "/shop/sidebar/list",
    name: "category show",
    component: () => import("@/views/shop/sidebar/_type.vue"),
  },
  {
    path: "/shop/sidebar",
    name: "param layout two",
    component: () => import("@/views/shop/sidebar/_type.vue"),
  },
  {
    path: "/shop/cart",
    name: "Cart",
    component: () => import("@/views/shop/cart/Cart.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/shop/cart/good",
    name: "GoodCart",
    component: () => import("@/views/shop/cart/GoodCart.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/shop/cart/certificate",
    name: "CertificateCart",
    component: () => import("@/views/shop/cart/CertificateCart.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/shop/orders",
    name: "Orders",
    component: () => import("@/views/shop/Orders.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/shop/operations",
    name: "Operations",
    component: () => import("@/views/shop/Operations.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/shop/favorites",
    name: "Favorites",
    component: () => import("@/views/shop/Favorites.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: () => import("@/views/Subscription.vue"),
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/shop/sidebar/:loyaut",
  //   name: "param layout",
  //   component: () => import("@/views/shop/sidebar/_type.vue"),
  // },
  {
    path: "/shop/sidebar/boxed",
    name: "param layout",
    component: () => import("@/views/shop/category/boxed.vue"),
  },
  {
    path: "/shop/without",
    name: "without two",
    component: () => import("@/views/shop/sidebar/_type2.vue"),
  },
  {
    path: "/shop/sidebar/without",
    name: "without two",
    component: () => import("@/views/shop/sidebar/_type2.vue"),
  },
  {
    path: "/shop/without/:without",
    name: "without",
    component: () => import("@/views/shop/sidebar/_type2.vue"),
  },
  {
    path: "/shop/nosidebar/boxed",
    name: "category show boxed",
    component: () => import("@/views/shop/nosidebar/_type.vue"),
  },
  {
    path: "/shop/nosidebar/fullwidth",
    name: "category show fullwidth",
    component: () => import("@/views/shop/nosidebar/_type.vue"),
  },
  {
    path: "/shop/fullwidth",
    name: "category fullwidth",
    component: () => import("@/views/shop/category/fullwidth.vue"),
  },
  {
    path: "/shop/category/fullwidth",
    name: "shop category fullwidth",
    component: () => import("@/views/shop/category/fullwidth.vue"),
  },
  {
    path: "/shop/category/boxed",
    name: "shop category boxed",
    component: () => import("@/views/shop/category/boxed.vue"),
  },
  {
    path: "/order/certificate",
    name: "OrderCertificate",
    component: () => import("@/views/order/OrderCertificate.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/avia/certificate",
    name: "OrderAvia",
    component: () => import("@/views/avia/OrderAvia.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/train/certificate",
    name: "OrderTrain",
    component: () => import("@/views/train/OrderTrain.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/hotels/certificate",
    name: "OrderHotels",
    component: () => import("@/views/hotels/OrderHotels.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/hotels/page",
    name: "HotelPage",
    component: () => import("@/views/hotels/HotelPage.vue"),
  },
  {
    path: "/hotel-search",
    name: "Hotels",
    component: () => import("@/views/HotelsPage.vue"),
  },
  {
    path: "/order/good",
    name: "OrderGood",
    component: () => import("@/views/order/OrderGood.vue"),
  },
  {
    path: "/my",
    name: "PersonalAccount",
    component: () => import("@/views/PersonalAccount.vue"),
  },
  {
    path: "/my/orders",
    name: "AccountOrders",
    component: () => import("@/views/PersonalAccount.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/my/operations",
    name: "Operations",
    component: () => import("@/views/PersonalAccount.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/my/favorites",
    name: "AccountFavorites",
    component: () => import("@/views/PersonalFavorites.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/order/confirmation",
    name: "OrderConfirmation",
    component: () => import("@/views/order/OrderConfirmation.vue"),
  },
  {
    path: "/order/confirmation-travel",
    name: "OrderConfirmationTravel",
    component: () => import("@/views/order/OrderConfirmationTravel.vue"),
  },
  {
    path: "/flight-search",
    name: "FlightSearch",
    component: () => import("@/views/FlightSearch.vue"),
  },
  // {
  //   path: "/flight-tickets2",
  //   name: "flight tickets2",
  //   component: () => import("@/views/flyght-tickets.vue"),
  // },
  {
    path: "/flight-tickets-correct",
    name: "flight tickets-correct",
    component: () => import("@/views/flyght-tickets-correct.vue"),
  },
  {
    path: "/operation",
    name: "operation",
    component: () => import("@/views/operation.vue"),
  },
  {
    path: "/operation/:id",
    name: "operation show",
    component: () => import("@/views/operation/operation_id.vue"),
  },
  {
    path: "/avia-result",
    name: "AviaResult",
    component: () => import("@/views/Avia.vue"),
  },
  {
    path: "/train-search",
    name: "TrainSearch",
    component: () => import("@/views/TrainSearch.vue"),
  },
  {
    path: "/train-result",
    name: "TrainResult",
    component: () => import("@/views/Train.vue"),
  },
  {
    path: "/travels",
    name: "Travels",
    component: () => import("@/views/Travels.vue"),
  },
  {
    path: "/order/travels",
    name: "OrderTravels",
    component: () => import("@/views/order/OrderTravels.vue"),
  },
  {
    path: "/card/accept-payment",
    name: "CardAcceptPayment",
    component: () => import("@/views/order/CardAcceptPayment.vue"),
  },
  {
    path: "/avia/accept-payment",
    name: "AviaAcceptPayment",
    component: () => import("@/views/order/CardAcceptAviaPayment.vue"),
  },
  {
    path: "/trains/accept-payment",
    name: "TrainAcceptPayment",
    component: () => import("@/views/order/CardAcceptTrainPayment.vue"),
  },
  {
    path: "/hotels/accept-payment-hotel",
    name: "HotelsAcceptPayment",
    component: () => import("@/views/order/CardAcceptHotePayment.vue"),
  },
  {
    path: "/card/payment-fail",
    name: "CardPaymentFail",
    component: () => import("@/views/order/CardPaymentFail.vue"),
  },
  {
    path: "/avia/payment-fail",
    name: "CardAviaPaymentFail",
    component: () => import("@/views/order/CardPaymentFail.vue"),
  },
  {
    path: "/hotels/payment-fail",
    name: "CardHotelsPaymentFail",
    component: () => import("@/views/order/CardPaymentFail.vue"),
  },
  {
    path: "/trains/payment-fail",
    name: "CardTrainsPaymentFail",
    component: () => import("@/views/order/CardPaymentFail.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const { isAuth, showLogin } = useAuth();
  if (to.meta.requiresAuth && !isAuth()) {
    showLogin();
  } else {
    next();
  }
});

router.afterEach(async () => {
  const { fetchUserData, isAuth } = useAuth();
  if (isAuth()) {
    await fetchUserData();
  }
});

export default router;
