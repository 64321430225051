import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "product product-2 d-flex flex-column" }
const _hoisted_2 = {
  id: "category_media",
  class: "product-media certificate-card"
}
const _hoisted_3 = {
  key: 0,
  class: "product-label label-circle label-top"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "product-body" }
const _hoisted_6 = { class: "product-cat" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "product-title" }
const _hoisted_9 = {
  key: 0,
  class: "product-price",
  style: {"gap":"5px"}
}

import { computed, toRef } from "vue";
import { Certificate } from "@/api/models/Certificate";
import { CommonCategory } from "@/api/models/CommonCategory";
import PriceFormatted from "@/components/elements/typography/PriceFormatted.vue";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'CetificateCard',
  props: {
    product: {},
    showLabel: { type: Boolean, default: true },
    pageType: { default: "Certificates" }
  },
  setup(__props: any) {

const props = __props;

const product = toRef(props.product);
const route = useRoute();

const currentLink = computed(() => {
  return props.pageType === "Charities"
    ? {
        name: "Certificate",
        params: { id: product.value.id },
        query: {
          vendorId: product.value.vendorId,
          type: "charity",
        },
      }
    : {
        name: "Certificate",
        params: { id: product.value.id },
        query: {
          vendorId: product.value.vendorId,
          categoryIds: currentCategories.value,
        },
      };
});

const currentCategories = computed(() => {
  return (
    route.query.categoryIds ||
    product.value?.categories.map((category) => category.id).join(",")
  );
});

const categories = computed(() => {
  return product.value?.categories.length
    ? product.value?.categories.map((category) => ({
        id: category.id,
        name: category.name,
      }))
    : [
        {
          id: 0,
          name: "Подарочные сертификаты",
        },
      ];
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("figure", _hoisted_2, [
      (_ctx.showLabel)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Топ"))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: currentLink.value,
        class: "top_products ozon_product"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: product.value.image ?? '',
            alt: "Product",
            class: "product-image"
          }, null, 8, _hoisted_4)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categories.value, (category) => {
          return (_openBlock(), _createElementBlock("span", {
            key: category.id
          }, [
            (category.id === 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(category.name), 1))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: {
              name: 'CertificatesStatic',
              query: { categoryIds: category.id },
            }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(category.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
          ]))
        }), 128))
      ]),
      _createElementVNode("h3", _hoisted_8, [
        _createVNode(_component_router_link, { to: currentLink.value }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(product.value.name), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      (product.value?.hasOwnProperty('price'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _cache[0] || (_cache[0] = _createElementVNode("span", null, "от", -1)),
            _createVNode(PriceFormatted, {
              price: product.value.price ?? 0,
              "price-name": "бонусов"
            }, null, 8, ["price"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})