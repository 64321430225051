import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pass-input-label pass-input-label_ls"
}
const _hoisted_2 = ["placeholder", "value"]
const _hoisted_3 = {
  key: 1,
  class: "pass-input-label remind-pass-input-label text-left"
}

import { toRefs } from "vue";
import { isNumberStrict } from "@/utilities/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberMemberInput',
  props: {
    modelValue: {},
    title: {},
    hint: {},
    placeholder: {},
    isValidMemberNumber: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const $emit = __emit;

const { title, modelValue, placeholder, hint, isValidMemberNumber } =
  toRefs(props);

const updateValue = (event: Event) => {
  const input = event.target as HTMLInputElement;
  let sanitizedValue = input.value.replace(/[^0-9]/g, "");
  if (sanitizedValue.length > 11) {
    sanitizedValue = sanitizedValue.slice(0, 11);
  }
  $emit("update:modelValue", sanitizedValue);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", null, [
    (_unref(hint))
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(hint)), 1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: _normalizeClass(["number-member-input", { 'input--invalid': !_unref(isValidMemberNumber) }]),
      name: "member",
      type: "text",
      placeholder: _unref(placeholder),
      value: _unref(modelValue),
      onInput: updateValue,
      onKeypress: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(isNumberStrict) && _unref(isNumberStrict)(...args))),
      inputmode: "numeric",
      pattern: "[0-9]*",
      maxlength: "11"
    }, null, 42, _hoisted_2),
    (_unref(title))
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(title)), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})