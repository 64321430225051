import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

import { toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthBtn',
  props: {
    title: {},
    type: {},
    outlined: { type: Boolean },
    disabled: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const $emit = __emit;

const { title, type, outlined } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'btn-outlined': _unref(outlined), 'btn-covered': !_unref(outlined) })
  }, [
    _createElementVNode("button", {
      class: _normalizeClass([{ 'pass-btn--disabled': _ctx.disabled }, "pass-btn"]),
      type: _unref(type),
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($emit('click')))
    }, _toDisplayString(_unref(title)), 11, _hoisted_1)
  ], 2))
}
}

})