<template>
  <div
    class="toast"
    :class="{
      'toast-warning': toastSettings.type === 'warning',
      'toast-error': toastSettings.type === 'error',
      'toast-success': toastSettings.type === 'success',
      'toast-default': toastSettings.type === 'default',
    }"
  >
    <div style="white-space: pre-wrap">
      {{ toastSettings.message }}
    </div>
    <ClearBtn :color="colorsText[toastSettings.type]" @click="hide" />
  </div>
</template>

<script setup>
import { computed, defineEmits } from "vue";
import ClearBtn from "@/components/elements/buttons/ClearBtn.vue";
import { useApplicationStore } from "@/store/application";

const applicationStore = useApplicationStore();

const $emit = defineEmits(["hide"]);
const hide = () => $emit("hide");

const toastSettings = computed(() => applicationStore.toastSettings);

const colorsText = computed(() => ({
  warning: "#000",
  error: "#fff",
  success: "#fff",
}));
</script>

<style scoped lang="scss">
.toast {
  position: fixed;
  padding: 16px;
  color: white;
  z-index: 1000;
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
  left: 50%;
  transform: translateX(-50%);
  top: 60px;
  border-radius: 8px;
  font-size: 12px;
  display: flex;

  @media (max-width: 568px) {
    left: 10%;
    right: 10%;
    transform: none;
    justify-content: center;
  }
}

.toast-warning {
  background-color: #ffc107;
  color: #000;
}

.toast-error {
  background-color: #dc3545;
}

.toast-success {
  background-color: #28a745;
}

.toast-default {
  background-color: #2d3338;
}
</style>
