import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "product product-2 recomendation-card" }
const _hoisted_2 = {
  class: "product-media",
  id: "category_media"
}
const _hoisted_3 = {
  key: 0,
  class: "product-label label-circle label-new"
}
const _hoisted_4 = {
  key: 1,
  class: "product-label label-circle label-sale"
}
const _hoisted_5 = {
  key: 2,
  class: "product-label label-circle label-top"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "product-body flex-1" }
const _hoisted_8 = { class: "product-cat" }
const _hoisted_9 = { class: "product-title" }
const _hoisted_10 = { class: "product-price" }

import PriceFormatted from "@/components/elements/typography/PriceFormatted.vue";
import { CommonCategory } from "@/api/models/CommonCategory";
import { GoodProduct } from "@/api/models/GoodProduct";
import { toRef } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RecomendationCard',
  props: {
    product: {},
    labels: {}
  },
  setup(__props: any) {

const props = __props;

const product = toRef(props.product);
const labels = toRef(props.labels);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("figure", _hoisted_2, [
      (labels.value.new)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Новинка"))
        : _createCommentVNode("", true),
      (labels.value.offer)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Выгодно"))
        : _createCommentVNode("", true),
      (labels.value.top)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Топ"))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: { name: 'Good', params: { id: product.value.id } },
        class: "top_products ozon_product"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: `${product.value.image}`,
            alt: "Product",
            class: "product-image"
          }, null, 8, _hoisted_6)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.value.categories, (category, index) => {
          return (_openBlock(), _createElementBlock("span", { key: index }, [
            _createVNode(_component_router_link, {
              to: { name: 'Good', params: { id: product.value.id } }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(category.name), 1)
              ]),
              _: 2
            }, 1032, ["to"]),
            _createTextVNode(" " + _toDisplayString(index < product.value.categories.length - 1 ? "," : ""), 1)
          ]))
        }), 128))
      ]),
      _createElementVNode("h3", _hoisted_9, [
        _createVNode(_component_router_link, {
          to: { name: 'Good', params: { id: product.value.id } }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(product.value.name), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(PriceFormatted, {
          price: product.value.price ?? 0,
          "price-name": "бонусов"
        }, null, 8, ["price"])
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mb-4" }, null, -1))
    ])
  ]))
}
}

})