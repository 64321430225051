import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "product product-2 mb-0 flex flex-column good-card" }
const _hoisted_2 = {
  key: 0,
  class: "product-label label-circle label-new"
}
const _hoisted_3 = {
  key: 1,
  class: "product-label label-circle label-sale"
}
const _hoisted_4 = {
  key: 2,
  class: "product-label label-circle label-top"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "product-body" }
const _hoisted_7 = { class: "product-cat" }
const _hoisted_8 = { class: "product-title" }
const _hoisted_9 = {
  key: 0,
  class: "product-price",
  style: {"gap":"5px"}
}
const _hoisted_10 = { key: 0 }

import { toRef, computed } from "vue";
import { GoodProduct } from "@/api/models/GoodProduct";
import { generateRandomNumber } from "@/utilities/number";
import { Certificate } from "@/api/models/Certificate";
import PriceFormatted from "@/components/elements/typography/PriceFormatted.vue";
import { CommonCategory } from "@/api/models/CommonCategory";
import { useRoute } from "vue-router";
import { decodeHtml } from "@/utilities/text";

type Props =
  | (GoodProduct & { type: "Good" } & { categories: CommonCategory[] })
  | (Certificate & { type: "Cert" } & { categories: CommonCategory[] });


export default /*@__PURE__*/_defineComponent({
  __name: 'GoodItemSlider',
  props: {
    product: {},
    currentIndex: {},
    showLabel: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;
const product = toRef(props, "product");
const currentIndex = toRef(props, "currentIndex");
const showLabel = toRef(props, "showLabel");
const route = useRoute();

const isGoodProduct = (
  product?: Props
): product is GoodProduct & { type: "Good" } & {
  categories: CommonCategory[];
} => {
  return !!(product && product.type === "Good");
};
const randomLabelNumner = generateRandomNumber(1, 3);
const currentLink = computed(() => {
  return isGoodProduct(product.value)
    ? {
        name: "Good",
        params: { id: product?.value?.id ?? 0 },
        query: {
          categoryIds: currentCategories.value,
          vendorId: product?.value?.vendorId ?? 0,
        },
      }
    : {
        name: "Certificate",
        params: { id: product?.value?.id ?? 0 },
        query: {
          vendorId: product?.value?.vendorId ?? 0,
          categoryIds: currentCategories.value,
        },
      };
});

const currentCategories = computed(() => {
  return (
    route.query.categoryIds ||
    product.value?.categories?.map((category) => category.id).join(",")
  );
});

const decodeName = (name: string) => {
  return decodeHtml(name);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("figure", {
      class: _normalizeClass(["product-media", { 'certificate-card': !isGoodProduct(product.value) }]),
      id: "category_media"
    }, [
      (showLabel.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_unref(randomLabelNumner) === 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Новинка"))
              : _createCommentVNode("", true),
            (currentIndex.value === 0 || _unref(randomLabelNumner) === 2)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Выгодно"))
              : _createCommentVNode("", true),
            (currentIndex.value === 0 || _unref(randomLabelNumner) === 3)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Топ"))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: currentLink.value,
        class: "top_products"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: `${product.value?.image}`,
            alt: "Product",
            class: "product-image"
          }, null, 8, _hoisted_5)
        ]),
        _: 1
      }, 8, ["to"])
    ], 2),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.value?.categories, (category) => {
          return (_openBlock(), _createElementBlock("span", {
            key: category.id
          }, [
            (isGoodProduct(product.value))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: {
              name: 'GoodsStatic',
              query: { categoryIds: category.id },
            }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(decodeName(category.name as string)), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: "/shop/catalogue/certificates"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Подарочный сертификат")
                  ])),
                  _: 1
                }))
          ]))
        }), 128))
      ]),
      _createElementVNode("h3", _hoisted_8, [
        _createVNode(_component_router_link, { to: currentLink.value }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(decodeName(product.value?.name as string)), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      (product.value?.hasOwnProperty('price'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (!isGoodProduct(product.value))
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, "от"))
              : _createCommentVNode("", true),
            _createVNode(PriceFormatted, {
              price: (product.value as GoodProduct).price ?? 0,
              "price-name": "бонусов"
            }, null, 8, ["price"])
          ]))
        : _createCommentVNode("", true),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mb-4" }, null, -1))
    ])
  ]))
}
}

})