import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex tw-whitespace-nowrap" }
const _hoisted_2 = { class: "tw-whitespace-nowrap" }
const _hoisted_3 = { class: "tw-whitespace-nowrap" }

import { toRef } from "vue";
import { numeral } from "@/utilities/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'PriceFormatted',
  props: {
    price: { default: 0 },
    priceName: {}
  },
  setup(__props: any) {

const props = __props;
const price = toRef(props, "price");
const priceName = toRef(props, "priceName");
const formatNumber = (input) => {
  const num = typeof input === "number" ? input : parseFloat(input);

  let formattedNumber;
  if (num >= 10000) {
    formattedNumber = numeral(num).format("0,0.00").replace(/,/g, " ");
  } else {
    formattedNumber = numeral(num).format("0.00");
  }

  return formattedNumber;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(formatNumber(Number(price.value!.toFixed(2)))), 1),
    _cache[0] || (_cache[0] = _createTextVNode(" ")),
    _createElementVNode("span", _hoisted_3, _toDisplayString(priceName.value), 1)
  ]))
}
}

})